<template>
  <v-card id="promotion-list">
    <!-- search -->
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <v-spacer></v-spacer>
      <div class="d-flex align-center pb-5">
        <v-text-field
          v-model="filters.searchQuery"
          single-line
          dense
          outlined
          hide-details
          placeholder="Поиск..."
          class="promotion-list-search me-3"
        ></v-text-field>
      </div>
    </v-card-text>

    <v-data-table
      :headers="headers"
      :items="orders"
      :server-items-length="totalCount"
      :loading="loading"
      :page.sync="filters.pageNumber"
      :items-per-page.sync="filters.pageSize"
      :sort-by.sync="filters.sortBy"
      :sort-desc.sync="filters.sortDesc"
      class="text-no-wrap"
    >

      <template #[`item.id`]="{item}">
        {{ item.id }}
      </template>

      <template #[`item.restaurant`]="{item}">
        {{ item.restaurant }}
      </template>

      <template #[`item.phoneNumber`]="{item}">
        {{ item.phoneNumber }}
      </template>

      <template #[`item.sum`]="{item}">
        {{ item.sum }}
      </template>

      <template #[`item.type`]="{item}">
        {{ item.type }}
      </template>

      <template #[`item.source`]="{item}">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon size="20"
                          v-bind="attrs"
                          v-on="on"
                          class="rounded-0">
                    {{ getIcon(item.source) }}
                  </v-icon>
                </template>
              </v-tooltip>
      </template>

      <template #[`item.isCreatedInRk`]="{item}">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon size="20"
                    v-bind="attrs"
                    v-on="on"
                    class="rounded-0">
              {{ getRkIcon(item.isCreatedInRk) }}
            </v-icon>
          </template>
        </v-tooltip>
      </template>

      <template #[`item.actions`]="{item}">
        <div class="d-flex align-center justify-center">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                v-on="on"
                :to="{ name: 'order-form-tab', params: { id: item.id } }"
              >
                <v-icon size="18">
                  {{ icons.mdiEye }}
                </v-icon>
              </v-btn>
            </template>
            <span>Детали</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mapFormHandlers } from '@/helpers/forms'
import {
  mdiTrendingUp,
  mdiCellphone,
  mdiWeb,
  mdiEye,
  mdiCheck,
  mdiClose,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEyeOutline,
  mdiPencilOutline,
} from '@mdi/js'
import { toDateSting, hasPermission } from '@/helpers/index'

export default {
  name: 'OrderList',
  data: () => ({
    icons: {
      mdiCellphone,
      mdiWeb,
      mdiEye,
      mdiCheck,
      mdiClose,
      mdiTrendingUp,
      mdiPencilOutline,
      mdiDeleteOutline,
      mdiDotsVertical,
      mdiEyeOutline,
    },
  }),
  computed: {
    ...mapFormHandlers('order', [
      'filters.pageNumber',
      'filters.pageSize',
      'filters.searchQuery',
      'filters.sortBy',
      'filters.sortDesc',
    ]),
    ...mapState('order', {
      orders: 'list',
      totalCount: 'totalCount',
      loading: 'loading',
    }),
    headers() {
      return [
        { text: 'Номер', value: 'id' },
        { text: 'Ресторан', value: 'restaurant' },
        { text: 'Телефон', value: 'phoneNumber' },
        { text: 'Сумма заказа', value: 'sum' },
        { text: 'Тип', value: 'type', sortable: false },
        { text: 'Источник', value: 'source', sortable: false },
        { text: 'Статус Rk7', value: 'isCreatedInRk', sortable: false },
        {
          text: 'Действия',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ]
    },
  },
  async created() {
    document.title = 'Заказы'
    this.fetchOrders()
  },
  mounted() {
  },
  methods: {
    ...mapActions('order', { fetchOrders: 'fetchList', }),
    getIcon(val) {
      switch (val) {
        case 'MobileApp':
          return this.icons.mdiCellphone
        case 'WebSite':
          return this.icons.mdiWeb
      }
    },
    getRkIcon(val) {
      switch (val) {
        case true:
          return this.icons.mdiCheck
        case false:
          return this.icons.mdiClose
      }
    },
    hasNotPermission(val){
      return !hasPermission(val);
    },
    toDate(val){
      return toDateSting(val);
    },
  },
}
</script>

<style lang="scss" scoped>
#promotion-list {
  .promotion-list-search {
    max-width: 10.625rem;
  }
}
</style>
